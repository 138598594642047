import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApiData } from '../redux/apiSlice'; // Adjust the path as needed
import DOMPurify from 'dompurify';
import { formatDate } from '../Utils/DateFormat';
import { IMAGE_URL } from '../Utils/urls';

export default function News() {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { apiData, loading, error } = useSelector((state) => state.api);

    useEffect(() => {
        dispatch(fetchApiData({ url: 'allNews' })); // Call the API using Redux
    }, [dispatch]);
    console.log(apiData)

    const newsDetails = (id)=>{
        navigate("/news-details", { state: { id } });
    }

    return (
        <>
            <Header />
            <div>
                <div className="sab_banner overlay" style={{paddingTop:"50px",paddingBottom:"50px"}}>
                    <div className="container">
                        <div className="sab_banner_text">
                            <h2>Latest News</h2>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">News</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="city_services2_wrap">
                    <div className="container">
                        <div className="row">
                            {
                                apiData?.data?.newsData?.map((newsItem) => (
                                    <div className="col-md-4 col-sm-6" key={newsItem.id}>
                                        <div className="city_service2_fig">
                                            <figure className="overlay">
                                                <img src={IMAGE_URL+newsItem.image} style={{height:"150px",objectFit:"cover"}} alt="" />
                                                <div className="city_service2_list">
                                                    <div className="city_service2_caption">
                                                        <h4>{newsItem.title}</h4>
                                                    </div>
                                                </div>
                                            </figure>
                                            <div className="city_service2_text">
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: DOMPurify.sanitize(
                                                            newsItem.description.length > 120
                                                                ? `${DOMPurify.sanitize(newsItem.description).slice(0, 60)}...`
                                                                : DOMPurify.sanitize(newsItem.description)
                                                        ),
                                                    }}
                                                ></p>
                                                <a onClick={()=>newsDetails(newsItem.id)} className="see_more_btn" style={{cursor:"pointer"}}>
                                                    See More <i className="fa icon-next-1"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}