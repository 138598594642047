import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import useFetch from '../ApiClient/GetApi';
import {PostApi} from "../ApiClient/PostApi";
import {error_toaster, success_toaster} from "../Utils/Toaster";
import Loader from '../Utils/Loader';
import { red } from '../Utils/colors';


export default function BallotDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const { apiData } = useFetch(`/ballotDetails/${location?.state?.id}`);
    const ballotDetails = apiData?.data?.ballotDetails;
    const [loading,setLoading] = useState(false);

    // Form state
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        gender: '',
        selectedAnswers: {}, // To track selected answers
    });

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // Handle checkbox changes for answers
    const handleAnswerChange = (questionId, answerId) => {
        setFormData((prev) => ({
            ...prev,
            selectedAnswers: {
                ...prev.selectedAnswers,
                [questionId]: answerId,
            },
        }));
    };

    // Handle form submission
    const handleSubmit =async (e) => {
        e.preventDefault();
        setLoading(true)
        let response = await PostApi("submitData",formData);
        if(response.data.status == "1"){
            success_toaster(response.data.message);
            setLoading(false);
            setFormData({
                name: '',
                email: '',
                gender: '',
                selectedAnswers: {}
            });
            navigate("/");
        }
        else{
            error_toaster(response.data.message);
            setLoading(false);
        }
    };

    return (
        <>
            <Header />
           {
            loading ? <Loader />: <div>
            <div className="sab_banner overlay" style={{paddingTop:"50px",paddingBottom:"50px"}}>
                <div className="container">
                    <div className="sab_banner_text">
                        <h2>Ballots</h2>
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item active">Ballots</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container" style={{ padding: "20px", maxWidth: "800px", margin: "40px auto" }}>
                <h3 style={{ textAlign: "center",margin:"20px 0px" }}>{ballotDetails?.ballotName}</h3>
                <p style={{ textAlign: "center" }}>
                    <strong>Description:</strong> {ballotDetails?.description}
                </p>

                {/* Ballot Form */}
                <form onSubmit={handleSubmit} className="ballot-form">
                    {/* Name and Email Fields in the same row */}
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                        {ballotDetails?.name && (
                            <div style={{ flex: "1", marginRight: "10px" }}>
                                <label htmlFor="name" style={{ display: "block", marginBottom: "5px" }}>Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                    style={{
                                        width: "100%",
                                        padding: "8px",
                                        border: "1px solid #ced4da",
                                        borderRadius: "4px",
                                    }}
                                />
                            </div>
                        )}

                        {ballotDetails?.email && (
                            <div style={{ flex: "1", marginLeft: "10px" }}>
                                <label htmlFor="email" style={{ display: "block", marginBottom: "5px" }}>Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                    style={{
                                        width: "100%",
                                        padding: "8px",
                                        border: "1px solid #ced4da",
                                        borderRadius: "4px",
                                    }}
                                />
                            </div>
                        )}
                    </div>

                    {/* Gender Field */}
                    {ballotDetails?.gender && (
                        <div style={{ marginBottom: "20px", textAlign: "start", border: "1px solid #ced4da", borderRadius: "5px", padding: "10px" }}>
                            <label style={{ display: "block", textAlign: "start" }}>Gender</label>
                            <div style={{ display: "inline-flex", gap: "20px" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <input
                                        type="radio"
                                        id="genderMale"
                                        name="gender"
                                        value="male"
                                        checked={formData.gender === 'male'}
                                        onChange={handleInputChange}
                                        required
                                        style={{ marginRight: "5px", marginBottom: "10px" }}
                                    />
                                    <label htmlFor="genderMale">Male</label>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <input
                                        type="radio"
                                        id="genderFemale"
                                        name="gender"
                                        value="female"
                                        checked={formData.gender === 'female'}
                                        onChange={handleInputChange}
                                        required
                                        style={{ marginRight: "5px", marginBottom: "10px" }}
                                    />
                                    <label htmlFor="genderFemale">Female</label>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Questions and Answers */}
                    {ballotDetails?.questions?.length > 0 && (
                        <div style={{ padding: "30px 0px" }}>
                            <h4 style={{ marginBottom: "20px", textAlign: "start" }}>Questions</h4>
                            {ballotDetails.questions.map((question) => (
                                <div key={question.id} style={{ marginBottom: "20px", textAlign: "start", border: "1px solid #ced4da", borderRadius: "5px", padding: "10px" }}>
                                    <h5>{question.question}</h5>
                                    {question.answers.map((answer) => (
                                        <div key={answer.id} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                            <input

                                                type="radio"
                                                id={`answer-${answer.id}`}
                                                name={`question-${question.id}`}
                                                value={answer.id}
                                                checked={formData.selectedAnswers[question.id] === answer.id}
                                                onChange={() => handleAnswerChange(question.id, answer.id)}
                                                style={{ marginRight: "10px", height: "20px", width: "2%" }}
                                            />
                                            <label htmlFor={`answer-${answer.id}`} style={{ marginBottom: 0 }}>{answer.answer}</label>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    )}
                     <button type="submit" style={{
                        padding: "10px 20px",
                        marginBottom:"40px",
                        backgroundColor: red,
                        color: "#fff",
                        border: "none",
                        borderRadius: "0px",
                        cursor: "pointer"
                    }}>Submit</button>

                   
                </form>
            </div>
        </div>
           }
            <Footer />
        </>
    );
}
