import React from 'react';
import { Link } from 'react-router-dom';
import useFetch from '../ApiClient/GetApi';

export default function Header() {
  const { apiData } = useFetch("headerData");
  console.log(apiData)

  // If there's no data yet, return a placeholder or loader
  if (!apiData || !apiData.data || !apiData.data.poll) return null;

  // Function to create the sentence for each poll result
  const renderNewsItems = () => {
    return apiData?.data?.poll?.map((poll, index) => {
      const {
        totalVoters,
        voteCasts,
        winningCandidateVotes,
        wrongVotes,
        year,
        candidate,
        pollingstation
      } = poll;

      return (
        <p key={index}>
          {`In ${pollingstation?.name}, ${candidate?.name} from ${candidate?.party?.name} received ${winningCandidateVotes} out of ${voteCasts} votes, with ${wrongVotes} reject votes. Total voters: ${totalVoters}, Year: ${year}.`}
        </p>
      );
    });
  };

  return (
    <div>
      <header>
        <div className="city_top_wrap" style={{padding:"10px 25px"}}>
          <div className="container-fluid">
            <div className="city_top_logo">
              <figure>
                <h1>
                  <Link to="/">
                    <img style={{ height: "100px", width: "100px", objectFit: "cover" }} src="images/logo.png" alt="kodeforest" />
                  </Link>
                </h1>
              </figure>
            </div>
            {/* <div className="city_top_news">
              <span>Latest News</span>
              <div className="city-news-marquee">
                <div className="news-items">
                  {renderNewsItems()}  {/* Render dynamic news items */}
                {/* </div>
              </div>
            </div>  */}
            
            <div className="city_top_social">
              <ul>
                <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                <li><a href="#"><i class="fa fa-times"></i></a></li>
                <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                {/* <li><a href="#"><i className="fa fa-youtube"></i></a></li> */}
                <li><a href="#"><i className="fa fa-google"></i></a></li>

              </ul>

            </div>
          </div>
        </div>

        <div className="city_top_navigation">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9">
                <div className="navigation">
                  <ul>
                    {/* <li><Link to="/">Home</Link></li> */}
                    <li><Link to="/contact-us">Contact Us</Link></li>
                    <li><Link to="/about-us">About Us</Link></li>
                    <li><Link to="/news">News</Link></li>
                    <li><Link to="/ballots">Ballots</Link></li>
                    <li><a href="http://portal.enertek.in/">Log In</a></li>


                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </header>

      {/* Custom CSS for the marquee */}
      <style jsx="true">{`
        .city-news-marquee {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          box-sizing: border-box;
        }

        .news-items {
          display: inline-block;
          animation: marquee 60s linear infinite; /* Adjust duration to control speed */
        }

        .news-items p {
          display: inline-block;
          padding-right: 50px;
          font-size: 16px;
          color: #333;
        }

        @keyframes marquee {
          0% {
            transform: translateX(100%); /* Start immediately offscreen to the right */
          }
          100% {
            transform: translateX(-100%); /* End offscreen to the left */
          }
        }
      `}</style>
    </div>
  );
}
