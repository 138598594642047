import React, { useEffect, useState } from 'react';
import { FaMapMarkedAlt, FaUsers, FaFlag, FaClipboardList } from 'react-icons/fa'; // Importing relevant icons
import './Home.css'; // Importing CSS for styling
import useFetch from '../ApiClient/GetApi';
import { red } from '../Utils/colors';

export default function KeyData() {
    const { apiData } = useFetch("keyData");
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    function calculateTimeLeft() {
        const targetDate = new Date('2024-12-07T00:00:00Z');
        const now = new Date();

        let difference = targetDate - now;

        if (difference < 0) {
            difference = 0;
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    }

    return (
        <div className="city_event2_wrap " style={{ background: "white", padding: "10px" }}>
            <h2>Quick Stats for Ghana’s 2024 Election</h2>
            <div className="key-data-line container" style={{ display: 'flex',flexWrap:"", alignItems: 'center', justifyContent: 'space-between', borderBottom: '2px solid #df193a', padding: '40px 0', background: red }}>
              
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div>
                        <FaMapMarkedAlt style={{ color: "white" }} size={40} />
                    </div>
                    <div style={{ marginLeft: '20px', color: "white", textAlign: "start" }}>
                        <h5 style={{ color: "white", margin: 0 }}>{apiData?.data?.regions}</h5>
                        <h5 style={{ color: "white", margin: 0 }}>Regions</h5>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div>
                        <FaMapMarkedAlt style={{ color: "white" }} size={40} />
                    </div>
                    <div style={{ marginLeft: '20px', color: "white", textAlign: "start" }}>
                        <h5 style={{ color: "white", margin: 0 }}>{apiData?.data?.consistuencies}</h5>
                        <h5 style={{ color: "white", margin: 0 }}>Constituencies</h5>
                    </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div>
                        <FaClipboardList style={{ color: "white" }} size={40} />
                    </div>
                    <div style={{ marginLeft: '20px', color: "white", textAlign: "start" }}>
                        <h5 style={{ color: "white", margin: 0 }}>{apiData?.data?.voters}</h5>
                        <h5 style={{ color: "white", margin: 0 }}>Total no of Voters</h5>
                    </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div>
                        <FaFlag style={{ color: "white" }} size={40} />
                    </div>
                    <div style={{ marginLeft: '20px', color: "white", textAlign: "start" }}>
                        <h5 style={{ color: "white", margin: 0 }}>{apiData?.data?.presidentialCandidates}</h5>
                        <h5 style={{ color: "white", margin: 0 }}>Presidential Candidates</h5>
                    </div>
                </div>

                {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div>
                        <FaUsers style={{ color: "white" }} size={40} />
                    </div>
                    <div style={{ marginLeft: '20px', color: "white", textAlign: "start" }}>
                        <h5 style={{ color: "white", margin: 0 }}>{apiData?.data?.ParlimentaryCandidates}</h5>
                        <h5 style={{ color: "white", margin: 0 }}>Parliamentary Candidates</h5>
                    </div>
                </div>
                */}
            </div>
            
            {/* Marquee for news */}
            <div className='container' style={{ background: "rgb(159 9 34)", padding: "10px", color: "white", textAlign: "center", borderTop: "1px solid white" }}>
                <marquee behavior="scroll" direction="left" scrollamount="5">
                    Latest News: Election dates announced! New candidates entering the race! Stay tuned for updates!
                </marquee>
            </div>
            
            {/* Countdown Timer */}
            <div className="container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-around' }}>
                <div className="card shadow" style={{ width: '150px', textAlign: 'center', background: red, color: 'white', borderRadius: "10px", padding: '20px 0' }}>
                    <div style={{ fontSize: '24px', fontWeight: "bold" }}>{timeLeft?.days}</div>
                    <div>days</div>
                </div>
                <div className="card shadow" style={{ width: '150px', textAlign: 'center', background: red, color: 'white', borderRadius: "10px", padding: '20px 0' }}>
                    <div style={{ fontSize: '24px', fontWeight: "bold" }}>{timeLeft?.hours}</div>
                    <div>hours</div>
                </div>
                <div className="card shadow" style={{ width: '150px', textAlign: 'center', background: red, color: 'white', borderRadius: "10px", padding: '20px 0' }}>
                    <div style={{ fontSize: '24px', fontWeight: "bold" }}>{timeLeft?.minutes}</div>
                    <div>minutes</div>
                </div>
                <div className="card shadow" style={{ width: '150px', textAlign: 'center', background: red, color: 'white', borderRadius: "10px", padding: '20px 0' }}>
                    <div style={{ fontSize: '24px', fontWeight: "bold" }}>{timeLeft?.seconds}</div>
                    <div>seconds</div>
                </div>
            </div>

        </div>
    );
}
