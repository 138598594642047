import React, { useState,useEffect } from 'react';
import useFetch from '../ApiClient/GetApi';
import { PostApi } from '../ApiClient/PostApi';

export default function Footer() {
  const [topCandidates, setTopCandidates] = useState([]); // State to store top 2 candidates
  const topCandidatesData = useFetch("topCandidatesData")
  console.log(JSON.stringify(topCandidatesData?.apiData));
  useEffect(() => {
    if (topCandidatesData.apiData?.data?.data) {
      setTopCandidates(topCandidatesData.apiData.data.data);
    }
  }, [topCandidatesData.apiData]);

  return (
    <div>
      <footer>
        <div className="widget_wrap overlay" style={{ paddingTop: "30px", paddingBottom: "30px" }}>
          <div className="" style={{ padding: "0px 60px" }}>
            <div className="row">
              <div className="col-md-2">
                <div className="widget_list">
                  <h4 className="widget_title">Explore</h4>
                  <div className='row' style={{ display: 'flex', justifyContent: "center" }}>
                    <div className="city_top_social" style={{ display: "contents" }}>
                      <ul>
                        <li style={{ border: '1px solid white' }}>
                          <a href="#"><i className="fa fa-facebook"></i></a>
                        </li>
                        <li style={{ border: '1px solid white' }}>
                          <a href="#"><i className="fa fa-times"></i></a>
                        </li>
                        <li style={{ border: '1px solid white' }}>
                          <a href="#"><i className="fa fa-linkedin"></i></a>
                        </li>
                        <li style={{ border: '1px solid white' }}>
                          <a href="#"><i className="fa fa-google"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="widget_list">
                  <h4 className="widget_title">Top 2 Candidates</h4>
                

                  {/* Display the Top 2 Candidates in a Table */}
                  {topCandidates.length > 0 && (
                    <table style={{ width: "100%", marginTop: "20px", color: "white", borderCollapse: "collapse", fontSize: "12px" }}>
                      <thead>
                        <tr>
                          <th style={{ border: "1px solid white", padding: "4px", width: "20%" }}>Name</th>
                          <th style={{ border: "1px solid white", padding: "4px", width: "20%" }}>Party</th>
                          <th style={{ border: "1px solid white", padding: "4px", width: "20%" }}>Total Votes</th>
                          <th style={{ border: "1px solid white", padding: "4px", width: "20%" }}>Vote %</th>
                          <th style={{ border: "1px solid white", padding: "4px", width: "20%" }}>Constituencies Won</th>
                        </tr>
                      </thead>
                      <tbody>
                        {topCandidates.map((candidate, index) => (
                          <tr key={index}>
                            <td style={{ border: "1px solid white", padding: "4px", textAlign: "center", width: "20%" }}>{candidate.name}</td>
                            <td style={{ border: "1px solid white", padding: "4px", textAlign: "center", width: "20%" }}>{candidate.party}</td>
                            <td style={{ border: "1px solid white", padding: "4px", textAlign: "center", width: "20%" }}>{candidate.totalVotes}</td>
                            <td style={{ border: "1px solid white", padding: "4px", textAlign: "center", width: "20%" }}>{candidate.votePercentage}</td>
                            <td style={{ border: "1px solid white", padding: "4px", textAlign: "center", width: "20%" }}>{candidate.constituenciesWon}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>

              <div className="col-md-3">
                <div className="widget_list">
                  <h4 className="widget_title">Info</h4>
                  <div className="widget_service">
                    <ul>
                      <li><p style={{ color: "white" }}>info@resultsinafrica.com</p></li>
                      <li><p style={{ color: "white" }}>+233509004055</p></li>
                      <li><p style={{ color: "white" }}>No.6 Saalai Street, Tesano, Accra</p></li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="widget_list">
                  <h4 className="widget_title">News Letter</h4>
                  <div className="widget_service">
                    <input type="text" name="text" placeholder="Email address" required />
                    <div className="city_news_search" style={{ marginTop: "5px" }}>
                      <button style={{ textAlign: "center" }} className="theam_btn border-color color">Subscribe Now</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="widget_copyright" style={{ padding: "10px 0px" }}>
                <p style={{ color: "white" }}>ResultsAfrica is a Grip360 Ltd Service</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
