import React, { useEffect } from 'react';
import useFetch from '../ApiClient/GetApi';
import { formatDate } from '../Utils/DateFormat';
import { IMAGE_URL } from '../Utils/urls';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApiData } from '../redux/apiSlice';
import { red } from '../Utils/colors';

// Utility function to strip HTML tags
function stripHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}

export default function NewsSection() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { apiData, loading, error } = useSelector((state) => state.api);
    useEffect(() => {
        dispatch(fetchApiData({ url: 'getNews' })); // Call the API using Redux
    }, [dispatch]);
    const newsDetails = (id) => {
        navigate("/news-details", { state: { id } });
    }
    return (
        <div>
            <div className="city_news_wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                            <div className="section_heading margin-bottom">
                                <span>Welcome to Local City</span>
                                <h2>News Releases</h2>
                            </div>

                            <div className='row'>
                                {
                                    apiData?.data?.newsData?.map((nn) => (
                                        <div className='col-md-4'>
                                            <div class="card" style={{ border: "1px solid lightgray", margin: "10px 0px" }}>
                                               <div style={{borderBottom:"1px solid lightgray"}}>
                                               <img class="card-img-top" src={IMAGE_URL + nn.image} style={{ height: "200px", objectFit: "cover" }} alt="Card image cap" />
                                               </div>
                                                <div class="card-body">
                                                    <h5 className="card-title" style={{ padding: "10px 0px", fontWeight: "bold" }}>
                                                        {nn.title.length > 30 ? `${nn.title.substring(0, 20)}...` : nn.title}
                                                    </h5>

                                                    <p class="card-text" style={{ textAlign: "start", padding: "0px 10px" }}>{nn?.description
                                                        ? stripHtmlTags(nn.description).length > 100
                                                            ? `${stripHtmlTags(nn.description).slice(0, 100)}...`
                                                            : stripHtmlTags(nn.description)
                                                        : "No description available."}</p>
                                                    <a
                                                        onClick={() => newsDetails(nn.id)}
                                                        style={{
                                                            padding: "10px",
                                                            margin: "5px",
                                                            color: "white",
                                                            width: "150px",
                                                            backgroundColor: red,
                                                            borderRadius: "0px",
                                                            cursor: "pointer",
                                                        }} className='btn btn-primary'>Details</a>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }


                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
