import React from "react";
import { Circles, Watch } from "react-loader-spinner";

export default function Loader() {
  return (
    <div
      className="position-fixed d-flex flex-column justify-content-center align-items-center w-100 h-100"
      style={{ top: 0, left: 0, zIndex: 50, backgroundColor: "#fff3cd" }}  
    >
      <div>
        <Watch width="200" color="#856404" />  
      </div>
      <p className="text-center font-weight-medium text-xl text-warning">
        Loading...
      </p>
    </div>
  );
}

export function Loader2() {
  return (
    <div
      className="position-relative d-flex flex-column justify-content-center align-items-center w-100 h-100"
      style={{ zIndex: 50 }}
    >
      <div>
        <Circles width={160} color="#856404" />  
      </div>
      <p className="text-center font-weight-medium text-xl text-warning">
        Loading...
      </p>
    </div>
  );
}
