import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import useFetch from "../ApiClient/GetApi";
import { red } from "../Utils/colors";
import { Link, useNavigate } from "react-router-dom";

export default function Graph() {
  const navigate = useNavigate();
  const { apiData } = useFetch("graphData");
  const nationalReport = useFetch("nationalReport");
  
  const [chartOptions, setChartOptions] = useState({
    series: [
      { name: "Parliamentary", data: [] },
      { name: "Presidential", data: [] },
    ],
    colors: ["#28C76F", "#EA5455"],
    chart: {
      type: "bar",
      height: 320,
      stacked: true,
      zoom: { enabled: true },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        columnWidth: "20%",
      },
    },
    dataLabels: { enabled: false },
    yaxis: { min: 0, max: 300, tickAmount: 5 },
    xaxis: { categories: [] },
    legend: { show: false },
    fill: { opacity: 1 },
  });

  const [nationalChartOptions, setNationalChartOptions] = useState({
    series: [
      { name: "Parliamentary", data: [] },
      { name: "Presidential", data: [] },
    ],
    colors: ["#28C76F", "#EA5455"],
    chart: {
      type: "bar",
      height: 320,
      stacked: true,
      zoom: { enabled: true },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        columnWidth: "20%",
      },
    },
    dataLabels: { enabled: false },
    yaxis: { min: 0, max: 300, tickAmount: 5 },
    xaxis: { categories: [] },
    legend: { show: false },
    fill: { opacity: 1 },
  });

  const [selectedConstituency, setSelectedConstituency] = useState(null);

  // Automatically select the first constituency when data is fetched
  useEffect(() => {
    if (apiData?.data?.chartList?.length && selectedConstituency === null) {
      setSelectedConstituency(apiData.data.chartList[0].constituency);
    }
  }, [apiData, selectedConstituency]);

  // Update graph based on selected constituency
  useEffect(() => {
    if (apiData?.data?.chartList && selectedConstituency) {
      const constituencyData = apiData.data.chartList.find(
        (constituency) => constituency.constituency === selectedConstituency
      );

      if (constituencyData) {
        const categories = [];
        const parliamentaryVotes = [];
        const presidentialVotes = [];
        let maxVote = 300;

        constituencyData.results?.forEach((result) => {
          const partyName = result?.party || "Unknown Party";
          categories.push(partyName);

          const presidentialVote = result?.presidentialVotes || 0;
          const parliamentaryVote = result?.parliamentaryVotes || 0;

          presidentialVotes.push(presidentialVote);
          parliamentaryVotes.push(parliamentaryVote);

          maxVote = Math.max(maxVote, presidentialVote, parliamentaryVote);
        });

        setChartOptions((prevOptions) => ({
          ...prevOptions,
          series: [
            { name: "Parliamentary", data: parliamentaryVotes },
            { name: "Presidential", data: presidentialVotes },
          ],
          xaxis: { categories },
          yaxis: { ...prevOptions.yaxis, max: maxVote + 50 },
        }));
      }
    }
  }, [apiData, selectedConstituency]);

  // Update national graph based on national report data
  useEffect(() => {
    if (nationalReport?.apiData?.data?.data) {
      const categories = [];
      const parliamentaryVotes = [];
      const presidentialVotes = [];
      let maxVote = 300;

      Object.entries(nationalReport.apiData.data.data).forEach(([party, votes]) => {
        categories.push(party);
        parliamentaryVotes.push(votes.Parliamentary || 0);
        presidentialVotes.push(votes.Presidential || 0);
        maxVote = Math.max(maxVote, votes.Parliamentary, votes.Presidential);
      });

      setNationalChartOptions((prevOptions) => ({
        ...prevOptions,
        series: [
          { name: "Parliamentary", data: parliamentaryVotes },
          { name: "Presidential", data: presidentialVotes },
        ],
        xaxis: { categories },
        yaxis: { ...prevOptions.yaxis, max: maxVote + 50 },
      }));
    }
  }, [nationalReport]);

  const handleTabClick = (constituency) => {
    setSelectedConstituency(constituency);
  };

  return (
    <div className="container">
      <h3>Select a constituency to view its results</h3>
      <div style={{ marginBottom: "20px" }}>
        {apiData?.data?.chartList?.map((constituency, index) => (
          <button
            key={index}
            onClick={() => handleTabClick(constituency?.constituency || "")}
            style={{
              padding: "10px",
              margin: "5px",
              width: "150px",
              backgroundColor:
                selectedConstituency === constituency?.constituency ? red : "#EEE",
              color:
                selectedConstituency === constituency?.constituency ? "#FFF" : "#000",
              border: "1px solid #CCC",
              cursor: "pointer",
            }}
          >
            {constituency?.constituency || "Unknown Constituency"}
          </button>
        ))}
      </div>

      {/* Constituency Chart */}
      {selectedConstituency && (
        <div style={{ border: "1px solid red", marginBottom: "30px" }}>
          <h4>Results for {selectedConstituency}</h4>
          <Chart
            options={chartOptions}
            series={chartOptions.series}
            type="bar"
            height={320}
          />
        </div>
      )}

      {/* National Report Chart */}
      <div style={{ border: "1px solid red  ", marginTop: "30px" }}>
        <h4>National Report</h4>
        <Chart
          options={nationalChartOptions}
          series={nationalChartOptions.series}
          type="bar"
          height={320}
        />
      </div>

      <div>
        <Link to="/results">
          <button
            style={{
              padding: "10px",
              margin: "5px",
              width: "150px",
              backgroundColor: red,
              color: "white",
              border: "1px solid #CCC",
              cursor: "pointer",
            }}
          >
            Latest Results
          </button>
        </Link>
      </div>
    </div>
  );
}
